<template>
  <div class="m-2">

      <b-table
        striped
        hover
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
       
      </b-table>
 
 
  </div>
</template>

<script>
import api from "@/api";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import {
  BBadge,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCardBody,
  BProgress,
  BFormGroup,
  BImg,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BTable,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BInputGroupAppend,
  BButton,
  BPagination,
} from "bootstrap-vue";
export default {
  components: {
    VuePerfectScrollbar,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BCardBody,
    BProgress,
    BBadge,
    BFormGroup,
    BImg,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BTable,
    BInputGroup,
    BFormInput,
    BFormSelect,
    BInputGroupAppend,
    BButton,
    BPagination,
  },
  data() {
    return {
      data: [],
      groub: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 40,
        wheelPropagation: false,
      },
      filterOn: [],
      fields: [
        { key: "title", label: "venue" },
        { key: "mac", label: "mac",  },
        { key: "connect_last", label: "connect last",sortable: true  },
        { key: "lastseen", label: "lastseen", sortable: true },
      ],
      /* eslint-disable global-require */
      items: [],
      perPage: 1000,
      pageOptions: [100, 500, 1000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    loadData() {
      //   this.Loading = true;
      const params = {
        status: "all",
      };
      console.log("params :>> ", params);
      api
        .post("dev_report", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data :>> ", response.data);
          this.items = response.data.data;
          this.totalRows = response.data.total;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss" scoped></style>
